@import 'common-flagship_tasting_room';

.b-tab_list-tab {
	font-size: 18px;
	font-weight: 600;
	letter-spacing: 1px;
	text-transform: none;
}

.ans-searchbar #ans-search-box-dialog {
	align-items: center;
	display: flex;
	grid-area: search-s/search-s/search-e/search-e;
	justify-content: center;
	margin: 0 auto;
	max-width: 100%;
	pointer-events: all;
	z-index: 0;
}

@include media(md-up) {
	.ans-searchbar #ans-search-box-dialog {
		max-width: 48vw;
		width: 384px;
	}
}

.ans-searchbar #ans-search-box-dialog .b-dialog_ans,
.ans-searchbar #ans-search-box-dialog .b-dialog_ans:not(.m-opened) {
	animation: none;
	display: flex;
	overflow: auto;
	position: relative;
	visibility: visible;
}

.ans-searchbar #ans-search-box-dialog .b-search_dialog {
	opacity: 1;
	transition: none;
}

.ans-searchbar #ans-search-box-dialog .b-search_dialog .b-search_dialog-inner {
	background: transparent;
	padding: 0;
}

.ans-searchbar #ans-search-box-dialog .b-search_dialog-form_wrap {
	margin-bottom: 0;
}

.ans-searchbar #ans-search-box-dialog .b-search_dialog .b-search_dialog-inner .b-search_input {
	border: 1px solid $color-search-border;
	margin: 0 1rem;
	padding: 10px 15px;
	width: 100vw;
}

.ans-searchbar #ans-search-box-dialog .b-search_dialog .b-search_dialog-inner .b-search_input-input {
	font-family: $font-main;
	font-size: 16px;
	font-weight: normal;
	line-height: 1;
	padding: 0;
}

.ans-searchbar #ans-search-box-dialog .b-suggestions .b-content_slot {
	display: none;
}

.ans-searchbar #ans-search-box-dialog .b-search_dialog.m-active_suggestions .b-suggestions {
	background-color: $color-primary-background;
	box-shadow: 0 3px 16px rgba(34, 56, 58, 0.1);
	left: 0;
	padding: 1.25rem 0 1.25rem 1rem;
	position: fixed;
	right: 0;
	top: var(--header-height, 135px);
}

.ans-searchbar .b-header-utility_item.m-search {
	display: none;
}

.ans-searchbar #ans-search-box-dialog .b-search_dialog.m-active_suggestions .b-suggestions .b-suggestions-inner {
	height: 60vh;
	margin: 0 auto;
	overflow-y: auto;
	padding-bottom: 100px;
}

@include media(md-up) {
	.ans-searchbar #ans-search-box-dialog .b-search_dialog .b-search_dialog-inner .b-search_input {
		max-width: 384px;
	}
}

@include media(md-up) {
	.ans-searchbar #ans-search-box-dialog .b-search_dialog.m-active_suggestions .b-suggestions .b-button_close {
		padding-right: 0;
		padding-top: 0;
	}
}

@include media(md-up) {
	.ans-searchbar #ans-search-box-dialog .b-search_dialog.m-active_suggestions .b-suggestions {
		top: var(--ans-suggestion-offset, 135px);
	}
}

@include media(md-up) {
	.ans-searchbar #ans-search-box-dialog .b-search_dialog.m-active_suggestions .b-suggestions {
		max-width: 808px;
		padding: 0;
		width: calc(100% - 70px);
	}
}

@include media(md-up) {
	.ans-searchbar #ans-search-box-dialog .b-search_dialog.m-active_suggestions .b-suggestions .b-suggestions-inner {
		height: auto;
		padding: 1rem;
	}
}

@include media(sm) {
	.ans-searchbar.b-header {
		grid-template: [start promo-s background-s] auto [promo-e hamburger-s logo-s utility-s] auto [hamburger-e logo-e utility-e search-s] 62px [background-e search-e nav-s] 1fr [end nav-e]/[start promo-s nav-s background-s search-s] minmax(var(--grid-margin), calc((100vw - 1720px) / 2)) [hamburger-s] auto [hamburger-e logo-s] auto [logo-e] 1fr [utility-s] auto [end utility-e] minmax(var(--grid-margin), calc((100vw - 1720px) / 2)) [end promo-e nav-e background-e search-e];
	}
}

@include media(md) {
	.ans-searchbar.b-header {
		grid-template: [start promo-s background-s] auto [promo-e hamburger-s logo-s search-s utility-s] auto [hamburger-e logo-e search-e utility-e nav-s background-e] 1fr [end nav-e] / [start promo-s nav-s background-s] minmax(var(--grid-margin), calc((100vw - 1720px) / 2)) [hamburger-s] auto [hamburger-e logo-s search-s] auto [logo-e] auto [utility-s] 1fr [end search-e utility-e] minmax(var(--grid-margin), calc((100vw - 1720px) / 2)) [end promo-e nav-e background-e];
	}
}

@include media(lg-up) {
	.ans-searchbar.b-header {
		grid-template-columns: [start promo-s background-s] minmax(var(--grid-margin), calc((100vw - 1720px) / 2)) [logo-s logo-e links-s nav-s search-s] 1fr [] 1fr [] 1fr [links-e] 1fr 1fr [] 1fr [] 1fr [] 1fr 1fr [utility-s] 1fr [] 1fr [] 1fr [search-e utility-e nav-e] minmax(var(--grid-margin), calc((100vw - 1720px) / 2)) [end promo-e background-e];
		grid-template-rows: [start promo-s background-s] auto [promo-e logo-s search-s utility-s] auto [logo-e search-e utility-e nav-s links-s] auto [end background-e nav-e links-e];
	}
}

@include media(lg-up) {
	.ans-searchbar.b-header.m-stuck #ans-search-box-dialog {
		display: none;
	}
}

@include media(lg-up) {
	.ans-searchbar.b-header.m-stuck .b-header-utility_item.m-search {
		display: flex;
	}
}

@include media(lg-up) {
	.ans-searchbar.b-header.m-stuck {
		grid-template: [start background-s promo-s] auto [promo-e logo-s nav-s utility-s] auto [end background-e logo-e nav-e utility-e]/[start promo-s background-s] 30px [logo-s] 1fr [] 1fr [logo-e nav-s] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [] 1fr [nav-e utility-s] 1fr [] 1fr [utility-e] 30px [end promo-e background-e];
	}
}


// Agegate Modifications
.b-age_gate {
	background-image: url('images/tbr-age-gate-bg.png');
}

.m-age_gate-open {
	.b-age_gate {
		.b-age_gate-header {
			background-color: $color-primary;
			border-radius: 50px;
			height: 114px;
			margin: 5px auto -76px;
			padding-top: 18px;
			width: 89px;
			z-index: 1;
		}

		.b-age_gate-main {
			background-color: $color-primary-background;
			border: 10px solid $color-primary;
			color: $color-primary;
			display: flex;
			flex-direction: column;
			justify-self: center;
			outline: 1px solid $color-primary-background;
			padding: 76px 10px 10px;

			@include media(md-up) {
				grid-column: header-e / header-s;
				width: 696px;
			}
		}

		.b-age_gate-inner {
			padding-top: 54px;

			@include media(sm) {
				padding-top: 10px;
			}
		}

		.b-age_gate-title {
			font-size: 40px;
			line-height: 48px;
			margin-bottom: 12px;
			order: 0;

			@include media(sm) {
				font-size: 34px;
				line-height: 42px;
			}
		}

		.b-age_gate-subtitle {
			margin-bottom: 0;
			order: 1;
			padding-bottom: 20px;

			.t-paragraph_3 {
				font-size: 24px;
				line-height: 32px;

				@include media(sm) {
					font-size: 20px;
					line-height: 30px;
				}
			}
		}

		.b-age_gate-form {
			display: grid;
			grid-column-gap: 16px;
			grid-template-columns: repeat(3, 108px);
			order: 2;

			@include media(sm) {
				grid-template-columns: repeat(2, 80px) 108px;
			}

			.age-gate-form-field{
				@include media(sm) {
					width: 100%;
				}
			}


			.age-gate-input {
				border: 2px solid $color-primary;
				width: 100%;

				&:placeholder-shown {
					border-color: rgba(34, 56, 58, 0.5);
					color: rgba(34, 56, 58, 0.5);
					margin-right: 0;

					&:focus {
						border-color: $color-primary;
						color: $color-primary;
					}
				}
			}

			.b-age_gate-submit {
				background-color: $color-age_gate-submit;
				border-color: rgba(255, 255, 255, 0);
				color: $color-primary;
				cursor: pointer;
				font-size: 18px;
				grid-column: 1/span 3;
				justify-self: center;
				margin-top: 30px;
				max-width: 152px;
				min-width: auto;
				width: 100%;
			}
		}

		.b-age_gate-description {
			margin-bottom: 0;
			order: 3;
			padding: 20px;
		}

		.too-young {
			font-size: 24px;
			line-height: 32px;
			margin: auto;
			max-width: 70%;
			order: 2;

			@include media(sm) {
				font-size: 20px;
				line-height: 30px;
				max-width: 350px;
			}
		}
	}
}

// fix menu close on mobile to account for search bar changes
.b-header.m-hamburger_opened .b-header-close_hamburger{
	top: -120px;
}

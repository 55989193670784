// # Applied colors palette #
//============================================
// ## Main colors ##
$color-primary: #22383a;
$color-primary-background: #fff;
$color-select-option: #0c0c0c;
$color-error: #ca1e36;//#a74a2f;
$color-error-background: #faeded;
$color-success: #2a815f;
$color-promo-background: #c4e6d8;
$color-notification-background: #d8d8d8;
$color-input-border: #a0a0a0;
$color-accent: #9a5b87;

// ## Shades ##
$color-shade_1: #252525;
$color-shade_2: #4d4d4d;
$color-shade_3: #757575;
$color-shade_4: #d8d8d8;
$color-shade_5: #efefef;
$color-shade_6: #fafafa;

// ## GH/GF colors ##
$color-header_text: $color-primary;
$color-header_alt: $color-primary-background;
$color-footer_text: $color-primary-background;
$color-footer_alt: $color-primary-background;

// ## Buttons ##
// ### Button Main ###
$color-button_main-background: $color-primary; //~1
$color-button_main-border: $color-primary; //~1
$color-button_main-text: $color-primary-background; //~1
$color-button_main__hover-background: transparent; //~1
$color-button_main__hover-border: $color-primary; //~1
$color-button_main__hover-text: $color-primary; //~1
$color-button_main__inversed-background: $color-primary-background; //~
$color-button_main__inversed-border: $color-primary-background; //~
$color-button_main__inversed-text: $color-primary; //~
$color-button_main__inversed__hover-background: $color-primary;
$color-button_main__inversed__hover-border: $color-primary;
$color-button_main__inversed__hover-text: #fff;
// ### Alt ###
$color-button_alt-background: transparent;
$color-button_alt-border: $color-primary;
$color-button_alt-text: $color-primary;
$color-button_alt__hover-background: $color-primary;
$color-button_alt__hover-border: $color-primary;
$color-button_alt__hover-text: $color-primary-background;
$color-button_alt__disabled-background: $color-button_alt-background;
$color-button_alt__disabled-border: $color-button_alt-border;
$color-button_alt__disabled-text: $color-button_alt-text;
$color-button_alt__inversed-background: transparent;
$color-button_alt__inversed-border: $color-primary-background;
$color-button_alt__inversed-text: $color-primary-background;
$color-button_alt__inversed__hover-background: $color-primary-background;
$color-button_alt__inversed__hover-border: $color-primary-background;
$color-button_alt__inversed__hover-text: $color-primary;
// ## Badges ##
$color-badge-1: #7c466d;
$color-badge-2: #757575;
//============================================

// Semantic colors mapping to components
// # COMMON #
$color-text__dark: $color-primary;
$color-text__light: $color-primary-background;
$color-overlay-background: $color-primary;

// # COMPONENTS #
// ## Product tile ##
$color-product_tile-promo-background: $color-promo-background;
$color-product_tile-promo: $color-primary;

// ## PRODUCT BADGE ##
$color-badge_2-color: $color-badge-1;

// ## Header ##
$color-header-background: $color-primary-background;
$color-header__transparent-text: $color-header_alt;
$color-header__transparent__inverted-text: $color-header_text;
$color-header__transparent-background-border: rgba($color-primary-background, 0.15);
$color-header__transparent-header_promo-background: $color-promo-background;
$color-header__transparent-header_promo-color: $color-primary;
$color-navigation_flyout-background: $color-primary-background;
$color-hamburger_menu-background: $color-primary-background;
$color-hamburger_back_link-background: $color-shade_6;
$color-hamburger_account-background: $color-shade_6;
$color-cookies-background: $color-shade_5;//??
$color-header_promo__transparent_header-background: $color-badge-2;//+
$color-main_nav-divider: $color-shade_5;//??
$color-mobile_navigation-categories_divider: $color-shade_5;//+
$color-mobile_navigation-section_divider: $color-shade_4;

// ## Footer ##
$color-footer-background: $color-primary;

// # CART AND CHECKOUT #
// ## CHECKOUT ##
$color-checkout-card_icon: $color-shade_3;

// ## PRODUCT WISHLIST CTA ##
$color-wishlist: $color-accent;

// # AGE GATE #
$color-age_gate-background: $color-primary;
$color-age_gate-submit:#bae2d3;


// # SEARCH #
$color-search-border: #e8eaeb;

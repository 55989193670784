// stylelint-disable

// # Source Sans Pro

// ## Regular

// ### latin-ext
@font-face {
	font-display: swap;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	src: local('Source Sans Pro Regular'),
		local('SourceSansPro-Regular'),
		url('./fonts/SourceSansPro/regular-latinext.woff2') format('woff2'),
		url('https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qNq7lqDY.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// ### latin
@font-face {
	font-display: swap;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 400;
	src: local('Source Sans Pro Regular'),
		local('SourceSansPro-Regular'),
		url('./fonts/SourceSansPro/regular-latin.woff2') format('woff2'),
		url('https://fonts.gstatic.com/s/sourcesanspro/v14/6xK3dSBYKcSV-LCoeQqfX1RYOo3qOK7l.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// ## Semi-bold

// ### latin-ext
@font-face {
	font-display: swap;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	src: local('Source Sans Pro SemiBold'),
		local('SourceSansPro-SemiBold'),
		url('./fonts/SourceSansPro/semibold-latinext.woff2') format('woff2'),
		url('https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwmRduz8A.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// ### latin
@font-face {
	font-display: swap;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 600;
	src: local('Source Sans Pro SemiBold'),
		local('SourceSansPro-SemiBold'),
		url('./fonts/SourceSansPro/semibold-latin.woff2') format('woff2'),
		url('https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3i54rwlxdu.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// ## Bold

// ### latin-ext
@font-face {
	font-display: swap;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	src: local('Source Sans Pro Bold'),
		local('SourceSansPro-Bold'),
		url('./fonts/SourceSansPro/bold-latinext.woff2') format('woff2'),
		url('https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwmRduz8A.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// ### latin
@font-face {
	font-display: swap;
	font-family: 'Source Sans Pro';
	font-style: normal;
	font-weight: 700;
	src: local('Source Sans Pro Bold'),
		local('SourceSansPro-Bold'),
		url('./fonts/SourceSansPro/bold-latin.woff2') format('woff2'),
		url('https://fonts.gstatic.com/s/sourcesanspro/v14/6xKydSBYKcSV-LCoeQqfX1RYOo3ig4vwlxdu.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// # Source Serif Pro

// ## Regular

// ### latin-ext
@font-face {
	font-display: swap;
	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 400;
	src: local('Source Serif Pro Regular'),
		local('SourceSerifPro-Regular'),
		url('./fonts/SourceSerifPro/regular-latinext.woff2') format('woff2'),
		url('https://fonts.gstatic.com/s/sourceserifpro/v10/neIQzD-0qpwxpaWvjeD0X88SAOeauXo-oBOL.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// ### latin
@font-face {
	font-display: swap;
	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 400;
	src: local('Source Serif Pro Regular'),
		local('SourceSerifPro-Regular'),
		url('./fonts/SourceSerifPro/regular-latin.woff2') format('woff2'),
		url('https://fonts.gstatic.com/s/sourceserifpro/v10/neIQzD-0qpwxpaWvjeD0X88SAOeauXQ-oA.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// ## Semi-bold

// ### latin-ext
@font-face {
	font-display: swap;
	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 600;
	src: local('Source Serif Pro SemiBold'),
		local('SourceSerifPro-SemiBold'),
		url('./fonts/SourceSerifPro/semibold-latinext.woff2') format('woff2'),
		url('https://fonts.gstatic.com/s/sourceserifpro/v10/neIXzD-0qpwxpaWvjeD0X88SAOeasasatSKqxLUv.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// ### latin
@font-face {
	font-display: swap;
	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 600;
	src: local('Source Serif Pro SemiBold'),
		local('SourceSerifPro-SemiBold'),
		url('./fonts/SourceSerifPro/semibold-latin.woff2') format('woff2'),
		url('https://fonts.gstatic.com/s/sourceserifpro/v10/neIXzD-0qpwxpaWvjeD0X88SAOeasasatSyqxA.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// ## Bold

// ### latin-ext
@font-face {
	font-display: swap;
	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 700;
	src: local('Source Serif Pro Bold'),
		local('SourceSerifPro-Bold'),
		url('./fonts/SourceSerifPro/bold-latinext.woff2') format('woff2'),
		url('https://fonts.gstatic.com/s/sourceserifpro/v10/neIXzD-0qpwxpaWvjeD0X88SAOeasc8btSKqxLUv.woff2') format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

// ### latin
@font-face {
	font-display: swap;
	font-family: 'Source Serif Pro';
	font-style: normal;
	font-weight: 700;
	src: local('Source Serif Pro Bold'),
		local('SourceSerifPro-Bold'),
		url('./fonts/SourceSerifPro/bold-latin.woff2') format('woff2'),
		url('https://fonts.gstatic.com/s/sourceserifpro/v10/neIXzD-0qpwxpaWvjeD0X88SAOeasc8btSyqxA.woff2') format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
// stylelint-enable

//New Fonts
$font-main: 'Source Sans Pro', sans-serif;
$font-alt: 'Source Serif Pro', serif;
